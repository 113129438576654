import "./App.css";
import useGoogleSheets from "use-google-sheets";
import { evaluate } from "mathjs";
import LazyLoad from "react-lazyload";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { format } from "date-fns";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import { useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import ReplayIcon from "@mui/icons-material/Replay";
import Tooltip from "@mui/material/Tooltip";
import { RTL } from "./providers/rtlCache";

const Fields = {
  category: "קטגוריה",
  type: "סוג שדה",
  name: "שם",
  positiveCondition: "תנאי חיובי",
  negativeCondition: "תנאי שלילי",
  data: "מידע",
};

//#region Styled Components start

const CustomCard = css`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 4px 10px 1px #0000000f;
  padding: 30px 32px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  gap: 36px;

  padding: 0px 0px 40px;

  @media (max-width: 600px) {
    padding: 0px;
    gap: 0px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 90px;
  ${CustomCard}
  padding: 0px;
  border-radius: 0px;

  @media (max-width: 600px) {
    min-height: auto;
  }
`;

const InnerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;
`;

const MainTitle = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: #241e1e;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 30px;
`;

const BodyContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 42px;
  width: 100%;
  max-width: 1100px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 22px 16px 160px;
    gap: 16px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${CustomCard}

  @media (max-width: 600px) {
    padding: 10px 20px 25px 20px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  max-height: 400px;

  ${CustomCard}

  video {
    width: 340px;
    height: 340px;
  }

  @media (max-width: 600px) {
    min-height: 370px;
    max-height: 370px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${CustomCard}
  padding: 0px;
  overflow: hidden;

  @media (max-width: 600px) {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 32px);
    padding: 0px;
    box-shadow: 0px 0px 10px 1px #0000004d;
  }
`;

const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #33485e;
  color: #fff;
  padding: 12px 0px;
  gap: 12px;
  position: relative;
`;

const Title = styled.span`
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const TotalSum = styled.span`
  font-size: 26px;
  direction: ltr;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px 16px 12px;
  width: 100%;
  gap: 4px;

  @media (max-width: 600px) {
    padding: 12px;
  }
`;

const StyledSelect = styled(Select)`
  height: 40px;
  display: flex;
  align-items: center;

  svg {
    right: 8px;
  }
`;

const PreviewImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 340px;
`;

const MobileTitle = styled.span`
  display: none;
  @media (max-width: 600px) {
    display: block;
    font-size: 16px;
  }
`;

const StyledButton = styled(Button)`
  background-color: #fc4929;
  padding: 12px 16px;
  font-size: 18px;
  border-radius: 6px;

  :hover {
    background-color: #eb4123;
  }
`;

const ResetButtonContainer = styled.div`
  position: absolute;
  left: 18px;

  @media (max-width: 600px) {
    left: 12px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: #fff;
`;

//#endregion Styled Components end

function groupByToMap(array, keySelector, transformElement = (x) => x) {
  const result = new Map();

  for (const el of array) {
    const key = keySelector(el);
    if (!result.has(key)) result.set(key, []);

    result.get(key).push(transformElement(el));
  }

  return result;
}

function parse([{ data: spreadsheetData }]) {
  let category = "";
  let name = "";
  let index = 0;
  const byIndex = [];

  const categories = groupByToMap(spreadsheetData, (x) => {
    if (x[Fields.category]) category = x[Fields.category];
    return category;
  });

  for (const key of categories.keys()) {
    categories.set(key, {
      type: categories.get(key)[0][Fields.type],
      options: groupByToMap(
        categories.get(key),
        // eslint-disable-next-line
        (x) => {
          if (x[Fields.name]) name = x[Fields.name];
          return name;
        },
        // eslint-disable-next-line
        (elemnt) => {
          /** @type {[]} */
          const positiveConditions = (elemnt[Fields.positiveCondition] ?? "")
            .split(",")
            .filter((x) => x)
            .map((x) => x.split(":"));

          /** @type {[]} */
          const negativeConditions = (elemnt[Fields.negativeCondition] ?? "")
            .split(",")
            .filter((x) => x)
            .map((x) => x.split(":"));

          const newItem = {
            positiveConditions,
            negativeConditions,
            data: elemnt[Fields.data],
            name: elemnt[Fields.name],
            index: index++,
          };
          byIndex[index - 1] = newItem;
          return newItem;
        }
      ),
    });
  }

  return [categories, byIndex];
}

function findValidVariation(values, allProps) {
  return values.find(
    (x) =>
      (!x.positiveConditions.length ||
        x.positiveConditions.every(
          ([name, val]) => allProps[name]?.name === val
        )) &&
      (!x.negativeConditions.length ||
        x.negativeConditions.every(
          ([name, val]) => allProps[name]?.name !== val
        ))
  );
}

function getSingle(parsed, allProps, type) {
  for (const [, { type: innerType, options }] of parsed) {
    if (innerType === type) {
      const data = findValidVariation([...options.values()][0], allProps)?.data;

      return data;
    }
  }
}
const getCenteredImage = async (id, doc, ratioMultiplier = 0.8) => {
  const canvas = await html2canvas(document.getElementById(id));
  const image = canvas.toDataURL("image/jpeg", 1.0);
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const widthRatio = pageWidth / canvas.width;
  const heightRatio = pageHeight / canvas.height;
  const ratio =
    (widthRatio > heightRatio ? heightRatio : widthRatio) * ratioMultiplier;

  const canvasWidth = canvas.width * ratio;
  const canvasHeight = canvas.height * ratio;

  const marginX = (pageWidth - canvasWidth) / 2;
  const marginY = 10;
  return { image, marginX, marginY, canvasWidth, canvasHeight };
};

const _exportPdf = async () => {
  const buttonToHide = document.querySelector("#price button");

  const originDisplay = buttonToHide.style.display;
  buttonToHide.style.display = "none";
  const doc = new jsPDF("p", "px", "a4");
  const details = await getCenteredImage("details", doc);
  const price = await getCenteredImage("price", doc, 0.7);

  buttonToHide.style.display = originDisplay;
  let y = 15;

  doc.text("MTI - " + format(new Date(), "dd/MM/yyyy"), 10, y);
  y += 10;
  doc.addImage(
    details.image,
    "JPEG",
    details.marginX,
    y,
    details.canvasWidth,
    details.canvasHeight
  );
  y += details.canvasHeight + 2;
  doc.addImage(
    price.image,
    "JPEG",
    price.marginX,
    y,
    price.canvasWidth,
    price.canvasHeight
  );

  doc.save("download.pdf");
};

function AfterParse({ parsed, byIndex }) {
  const dropDownKeys = useMemo(
    () =>
      [...parsed]
        .filter(([, { type }]) => type === "DropDown")
        .map(([key]) => key),
    [parsed]
  );

  const defaultValues = useMemo(
    () =>
      dropDownKeys.reduce((a, c) => {
        a[c] = "";
        return a;
      }, {}),
    [dropDownKeys]
  );

  const { watch, control, resetField, reset } = useForm({
    defaultValues,
  });

  let allProps = watch();
  dropDownKeys.forEach(
    (k) => allProps[k] != null && (allProps[k] = byIndex[allProps[k]])
  );

  const priceEquation = getSingle(parsed, allProps, "Equation");

  let priceContent = "-";
  try {
    const scope = Object.fromEntries(
      Object.entries(allProps).map((x) => [x[0], x[1]?.data])
    );
    priceContent =
      "₪ " + evaluate(priceEquation, { v: scope }).toLocaleString();
  } catch {}

  const mediaData = getSingle(parsed, allProps, "Media");
  const title = getSingle(parsed, allProps, "Title");
  const priceTitle = getSingle(parsed, allProps, "PriceTitle");
  const logo = getSingle(parsed, allProps, "Logo");

  const categoryToValidOptions = dropDownKeys.map((category) => {
    const { options } = parsed.get(category);
    const validOptions = Array.from(options, ([, values]) =>
      findValidVariation(values, allProps)
    ).filter((x) => x);

    if (
      allProps[category] != null &&
      validOptions.every((x) => x.index !== allProps[category].index)
    )
      resetField(category);

    return { category, validOptions };
  });

  return (
    <Container>
      <Header>
        <InnerSection>
          <MainTitle>{title}</MainTitle>
          <Logo src={logo} />
        </InnerSection>
      </Header>
      <BodyContent>
        <Actions id="details">
          {categoryToValidOptions.map(({ category, validOptions }) => (
            <RTL key={category}>
              <FormControl variant="standard">
                <InputLabel id={category}>{category}</InputLabel>
                <Controller
                  render={({ field }) => (
                    <StyledSelect
                      fullWidth
                      labelId={category}
                      {...field}
                      disabled={!validOptions.length}
                    >
                      {validOptions.map((x) => (
                        <MenuItem key={x.name} value={x.index}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                  name={category}
                  control={control}
                />
              </FormControl>
            </RTL>
          ))}
        </Actions>
        <PreviewContainer>
          <ImageContainer>
            <MobileTitle>תצוגה מקדימה</MobileTitle>
            <LazyLoad>
              <PreviewImage src={mediaData} />
            </LazyLoad>
          </ImageContainer>
          <PriceContainer>
            <Price id="price">
              <Title>{priceTitle}</Title>
              <TotalSum>{priceContent}</TotalSum>
              <ResetButtonContainer>
                <Tooltip title="איפוס" placement="top">
                  <StyledIconButton
                    color="primary"
                    aria-label="refresh"
                    size="small"
                    onClick={() => reset()}
                  >
                    <ReplayIcon fontSize="small" />
                  </StyledIconButton>
                </Tooltip>
              </ResetButtonContainer>
            </Price>
            <ButtonContainer>
              <RTL>
                <StyledButton
                  variant="contained"
                  fullWidth
                  endIcon={<DownloadIcon />}
                  onClick={_exportPdf}
                >
                  הורד הצעת מחיר
                </StyledButton>
              </RTL>
            </ButtonContainer>
          </PriceContainer>
        </PreviewContainer>
      </BodyContent>
    </Container>
  );
}
function App() {
  const sheetFromQuery = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    return query.get("sheet");
  }, []);
  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: sheetFromQuery || "Sheet1" }],
  });
  const [parsed, byIndex] = useMemo(
    () => (!loading && parse(data)) || [],
    [data, loading]
  );

  error && console.error("Failed to load spreadSheet: ", error);

  if (loading)
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );

  return <AfterParse parsed={parsed} byIndex={byIndex} />;
}

export default App;
